import React, { FC } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'gatsby';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';

import { FaqSearchProps } from './models';

import './FaqSearch.scss';

const FaqSearch: FC<FaqSearchProps> = ({ items, search, link, name }) => {
  const [faqs, setFaqsState] = React.useState(items);

  const filter = (keyword) => {
    const look = keyword.toLowerCase();
    const filtered = items.filter(
      (item) => item.title.toLowerCase().includes(look) || item.body.toLowerCase().includes(look)
    );
    setFaqsState(filtered);
  };

  return (
    <div className="container section-container">
      <div className="row pt-5 pb-4">
        <div className="col">
          <div className="faq__search p-3">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder={search}
                aria-label={search}
                aria-describedby="basic-addon2"
                onChange={(e) => filter(e.target.value)}
              />
              <div className="input-group-append">
                <button className="btn" type="button">
                  <i className="fas fa-search" />
                </button>
              </div>
            </div>
            <div className="text-end pt-2">
              <Link to={link.url}>{link.name}</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h2 className="pt-4 faq__header">{name}</h2>
          <Accordion className="faq__items pb-5">
            {faqs.map(({ title, body }, index) => (
              <Accordion.Item eventKey={String(index)} key={title} data-testid="faq-item">
                <Accordion.Header>{title}</Accordion.Header>
                <Accordion.Body className="py-2 ps-1">
                  <DangerouslySetInnerHtml html={body} element="div" className="mb-0" />
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default FaqSearch;

import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import FaqSearch from 'components/FaqSearch';

import { FaqProps } from './models';

import './Faq.scss';

const Faq: FC<{ data: FaqProps }> = ({
  data: {
    faq: { urls, seo, name, image, items, link, search },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <div className="faq">
        <div className="faq__image">
          <Image imageData={image} alt={name} className="w-100" />
        </div>
        <FaqSearch items={items} name={name} link={link} search={search} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    faq(url: { eq: $url }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      name
      image {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
      }
      items {
        title
        body
      }
      link {
        url
        name
      }
      search
    }
  }
`;

export default Faq;
